.fixedBinTable {
    thead {
        tr:first-child {
            display: none;
        }
    }
    table-layout: fixed;
}

.buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-end;
    padding: 8px;
    box-sizing: border-box;
}