.header {
  font-weight: 600
}

.filters-row {
  margin-bottom: 10px;
}

.dashboard-filters-modal .ant-modal-content {
  width: 1000px;
}

.saveButton {
  height: 40px;
  margin-left: 5px;
}

.ant-checkbox-group {
  display: block;
  margin-right: 0;
}

.settingsCard > .ant-card-head {
  padding: 0px 12px;
}
