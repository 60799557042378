.newStockTable .ant-table-thead > tr > th {
  padding-top: 0px;
  padding-bottom: 8px;
  font-weight: 400;
}
.newStockTable .ant-table-tbody > tr > td {
  padding: 3px 0px 38px 0px;
}
.newStockTable table { 
  font-size: 14px; 
  width: 730px;
}

.newStockTable td.ant-table-cell {
  padding: 0px !important;
  height: 60.45px;
}

/* .newStockTable col {
  width: 90px!important;
  min-width: 90px!important;
} */