.massUpdateMrpContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.openDocsTooltip {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
}

.monImpContainer {
    display: flex;
    flex-direction: column;
    gap: 0;
}

.monImpInfo {
    font-size: 10px;
    line-height: 10px;
    font-weight: 600;
}

.populateInputs::-webkit-input-placeholder {
    color: black;
}

.extraButtons {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.memo {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.buttonRow {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: flex-end;
}

.inputError {
    border: 1px solid red !important;
}

.tableContainer {
    position: relative;
    min-height: 400px;
    width: 100%;
}

.loadingView { 
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
}

.materialCheckbox {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
}
.materialDescription {
    text-overflow: ellipsis;
    white-space: nowrap;
}

.statusField {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadingContent {
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.loadingText {
    padding: 2rem;
    background-color: #fff;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
}
