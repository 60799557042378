.widgetHeight {
    height: calc(((100vw - 80px) / 3) * 0.3);
}

@media screen and (max-width: 1800px) {
    .widgetHeight {
        height: calc(((100vw - 80px) / 3) * 0.5);
    }
}

@media screen and (max-width: 1200px) {
    .widgetHeight {
        height: calc(((100vw - 80px) / 3));
    }
}