.ant-picker-month-panel {
  border-right: 1px solid rgb(240,240,240);
}

.invalidImpactInput .ant-input-wrapper-focused {
  border: 1px solid #e24973 !important;
  box-shadow: 0 0 0 2px rgb(255 77 79 / 20%) !important;
}

.invalidImpactInput .ant-input {
  border: 1px solid #e24973 !important;
  box-shadow: 0 0 0 2px rgb(255 77 79 / 20%) !important;
}
.invalidImpactInput .ant-input:focus {
  border: 1px solid #e24973 !important;
  box-shadow: 0 0 0 2px rgb(255 77 79 / 20%) !important;
}
.invalidImpactInput .ant-input:hover {
  border: 1px solid #e24973 !important;
  box-shadow: 0 0 0 2px rgb(255 77 79 / 20%) !important;
}
