.newNotification {
  border-left: 5px solid #1890ff !important;
  border-radius: 4px !important;
  margin-right: 10px !important;
}

.newNotificationTitle {
  font-weight: bold;
}

.acceptedNotification {
  border-left: 5px solid #f0f0f0 !important;
  border-radius: 4px !important;
  margin-right: 10px !important;
}