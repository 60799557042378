.fixedBinLayout {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


.buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-end;
    padding: 8px;
    box-sizing: border-box;
}

.populateInputs::-webkit-input-placeholder {
    color: black;
}

.headerInput {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.clearIcon {
    height: 20px;
    width: 20px;
    cursor: not-allowed;
    svg {
        height: 20px;
        width: 20px;
        cursor: not-allowed;
    }
}

.activeIcon {
    cursor: pointer;
    svg {
        cursor: pointer;
    }
}