.plantSelector {
  width: 45vw;
  max-width: 45vw;
}

.revertButton {
  background-color: #EB0000;
  border: 1px solid #EB0000;
  color: white;

}

.revertButton:hover {
  background-color: #B30D2F;
  border: 1px solid #B30D2F;
  color: white;
}

.table-row-highlight .ant-table-cell, .table-row-highlight  {
  background-color: rgb(255,168,1, 0.65)!important;
}

.table-row-highlight .ant-table-cell.ant-table-cell-row-hover {
  background-color: rgb(255,168,1, 0.35)!important;
}

.populateInputs {
  width: 96%;
}

.populateInputs::placeholder {
  color: black !important;
  opacity: 1;
  
}



.populateInputs option:not(:first-of-type) {
  color: black;
} 
.populateInputs::-webkit-input-placeholder {
  color:red;
}

.populateInputs::-moz-placeholder {
  color:red;
}

.populateInputs::-ms-placeholder {
  color:red;
}

.populateInputs::placeholder {
  color:red;
}

.ant-select-selection-placeholder {
  color: black !important;
  font-weight: 400;
}