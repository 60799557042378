.tabTable .ant-table-cell, .tabTable .ant-table-column-sorters {
  font-size: 12px;
}

.tabTable .ant-table-pagination.ant-pagination {
  margin: 8px 0px;
}

.excluded .ant-table-cell, .excluded .ant-table-cell.ant-table-cell-row-hover {
  background-color: rgb(255,168,1, 0.65)!important;
}

.tabCard .ant-card-body {
  height: 100%;
}

.tabTable .ant-spin-container {
  height: 100%;
}

.tabTable .ant-spin-nested-loading {
  height: 100%;
}

.tabTable .ant-table-small {
  height: 100%;
}

.tabTable .ant-table-container {
  height: 100%;
}

.loadMoreButton {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  background-color: #188fff76;
  color: #fff;
  padding: 0.5rem 0rem;
  border-radius: 5px;
  visibility: hidden;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s;
}

#tableContainerForButton:hover .loadMoreButton {
  visibility: visible;
  opacity: 1;
}

.loadMoreButton:hover {
  background-color: #1890ff;
}
