.topbar-dragger {
  width: 100%;
  cursor: ns-resize;
  padding: 5px 0px;
  border-top: 1px solid #ddd;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 100;
  background-color: #fff;
  text-align: center;
  font-size: 16px;
}

.sidebar-dragger {
  width: 15px;
  cursor: ew-resize;
  padding: "4px 0 0";
  border-top: 1px solid #ddd;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  background-color: #fff;
}

.drag-icon {
  margin: 0;
  position: absolute;
  top: 50%;
  left: -50%;
  font-size: 38px;
  color: gray;
}