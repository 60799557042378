.ant-modal {
  width: fit-content !important;
  /* alternative without using !important
  margin-left: 15vh;
  margin-right: 15vh; */
}


.plannedOrderModal .ant-modal-content {
  width: 100%;
  max-width: 1600px;
} 