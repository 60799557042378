 .buttonContainer  {
    display: flex;
    justify-content: space-between;
}

.buttonContainer .ant-btn {
  margin: 6px;
}

.operatorSymbol {
  font-size: 18px;
  margin-right: 6px;
}

.rc-virtual-list-scrollbar-show {
  display: block !important;
}
