.widgetText {
  font-size: 18px;
  font-weight: 600  ;
}
.numberText {
  font-size: 20px;
  font-weight: 600;
}

body {
  overflow-x:hidden;
}