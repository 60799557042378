.quickSearchModal .ant-modal-content {
  width: 1000px;
}

.quickSearchCol1 {
  width: 15%;
  text-align: right;
}
.quickSearchCol2 {
  width: 20%;
  text-align: left;
}

/* .quickSearchModalPlacement {
  width: 325px;

} */

.quickSearchRows {
  margin-bottom: 10px;
}

.quickSearchInfo {
  font-size: 20px;
  padding-top: 8px;
  padding-left: 7px;
}

.selectInputError  {
  outline: 1px solid red;
}

.selectInputError .ant-select-selector {
  border: 0px!important;
  box-shadow: none;
}

.prodItem{
  background-color: var(--prod-color);
}

.nonProdItem {
  background-color: var(--non-prod-color);
}

.ant-switch {
  background-color: #94949495 !important;
}

.ant-switch-checked {
  background-color: #1890ff !important;
}