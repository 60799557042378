.limitedViewer {
    position: absolute;
    left: 0;
    z-index: 20;
    margin-left: 80px;
    display: flex;
    align-items: center;
    height: 100%;
}

.menuItem {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s;
    font-size: 10px;
    gap: 4px;
    color: #fff !important;
    height: 60px;
    text-decoration: none;

    a {
        color: #fff !important;
        text-decoration: none;
    }

    span svg {
        height: 20px;
        width: 20px;
    }

    .indicator {
        display: none;
    }
}

.asButton {
    background-color: #1d5b95;
    height: 64px;
    transition: all 0.2s;
    // border-bottom-right-radius: 10px;
}

.indicator {
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(270deg, #188fff96, transparent);
    display: none;
}

.bottomDivider {
    position: absolute;
    bottom: 0;
    width: 60%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height: 1px;
    background-color: #ffffff39;
}

.headerMenuItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s;
    font-size: 10px;
    gap: 4px;
    color: #fff !important;
    padding: 0rem 3rem;
    box-sizing: border-box;

    width: 60px;
    text-decoration: none;

    span svg {
        height: 20px;
        width: 20px;
    }
    
    .toggleIcon svg {
        height: 12px;
        width: 12px;
    }
}

.header {
    height: 64px;
    color: rgba(0,0,0, .85);
    position: relative;
    font-weight: 400 !important;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}

.rightHeader {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    margin-right: 80px;
    gap: 2rem;
}

.rightMenuItems {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
}

.divider {
    height: 60%;
    width: 1px;
    background-color: #ffffff39;;
}

.menu {
    overflow-y: auto;
    height: 100%;
    position: relative;
    width: 80px;
    overflow-y: auto;
}

.linkText {
    color: #dffbffab;
    height: fit-content;
    transition: color 0.2s;
}

.menuItem:hover, .headerMenuItem:hover {
    background-color: #00000065;
    color: var(--contrast-color) !important;
}

.active {
    color: var(--contrast-color) !important;

    .linkText {
        color: #fff !important;
    }

    .indicator {
        display: block;
    }
}

.dropdown {
    position: absolute;
    top: 64px;
    left: 0;
    width: 100%;
    z-index: 50;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    border-radius: 5px;
    transition: opacity 2s;
    overflow: hidden;
}

.closedDropdown {
    display: none;
}

.openDropdown {
    display: block;
}

.asButton:hover {
    background-color: #1890ff;

    color: #fff !important;

    .linkText {
        color: #fff !important;
    }
}

