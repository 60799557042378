.newStockForm input { font-size: 14px; }
.newStockForm .ant-form-item-label > label { 
    font-size: 14px; 
    margin: 17px 0px;
}
.newStockForm .ant-input-number-group-addon { font-size: 14px; }
.newStockForm .ant-select-selector { font-size: 14px; }

.newStockGrid {
    display: flex;
    gap: 12px;
    flex-direction: row;
    flex-wrap: wrap;
}

.results {
    padding-left: 12px;
    box-sizing: border-box;
    border-left: 1px dashed #efefef;
}