.multiPlantTable .ant-table-thead > tr > th {
  height: 8px;
  padding: 8px 4px;
}
.multiPlantTable .ant-table-tbody > tr > td {
  height: 8px;
  padding: 4px 4px;
}

.multiPlantTable .ant-table-thead > tr:nth-child(1) > th:nth-child(2),
.multiPlantTable .ant-table-thead > tr:nth-child(2) > th:nth-child(1),
.multiPlantTable .ant-table-thead > tr:nth-child(2) > th:nth-child(2) {
  background-color: #1890ff;
  color: white;
  border: 0px !important;
}

.inputNumber .ant-input-number-input-wrap > input {
  text-align: center;
}
