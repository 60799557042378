.changed .ant-input, .changed {
  background-color: #e8d0664d;
}

.invalid .ant-input, .invalid {
  background-color: #e2497248;
}

.configHeader .ant-page-header-content {
  padding-top: 0px
}
