.detailCard {
  text-align: center;
  overflow: hidden;
  overflow-y: auto;
  padding-top: 10px;
}

.longtext {
  white-space: pre-line;
  text-align: left;
  padding-left: 10px;
}
.ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 4px;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
  padding: 4px;
}

.ant-card-body {
  padding: 6px 12px;
}
.ant-descriptions-header {
  margin-bottom: 5px;
}

.editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: white;
  font-size: 12px;
  height: 20px;
}

.headline {
  font-size: 12px;
  font-weight: 600;
  color: black;
}

.headline.recommended {
  display: flex;
  justify-content: space-between;

}

.imgContainer {
  height: 80vh;
}

img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  display: block;
}

.icon {
  font-size: 18px;
  padding: 0px 5px;
}

.hidden {
  visibility: hidden;
}

.infoTable .ant-table {
  font-size: 12px;
}
.infoTable .ant-table-tbody > tr > td {
  height: 5px;
  padding: 0px 4px;
}
.infoTable .ant-table-thead > tr > th {
  height: 5px;
  padding: 0px 4px;
}

.bottomRightButton {
  float: right;
  margin: 15px 5px;
}

.IOTAComment .ant-comment-inner {
  padding: 0 2px 0 4px;
  border-top: 1px solid #f0f0f0 !important;
  border-left: 3px solid #1890ff !important;
  border-radius: 4px !important;
  margin: 10px 0 0 0;
}

.deleteComment .ant-typography {
  color:  #1890ff !important;
  cursor: pointer;
}

.deleteComment .ant-typography:hover {
  color:  #e24973 !important;
}

/* Custom scrollbar width */
.leftColumn ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Custom scrollbar track */
.leftColumn ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}

/* Custom scrollbar handle */
.leftColumn ::-webkit-scrollbar-thumb {
  background: grey; 
  border-radius: 10px;
}

.tabTitle {
  font-size: 12px;
}

.commentContainer {
  height: 14vh;
  -webkit-mask-image: linear-gradient(180deg, #000 90%, transparent);
  mask-image: linear-gradient(180deg, #000 90%, transparent);
}

.commentContainerEmpty {
  align-items: center;
  justify-content: center;
  height: 14vh;
}

.commentParagaph {
  white-space: pre-line;
  text-align: left;
  padding-left: 10px;
  font-size: 12px;
}

.monetaryImpact .ant-table-title {
  padding: 0px;
  color: 'red'
}

.monetaryImpact .ant-table-thead {
  display: none
}

.monetaryImpact .headline {
  background-color: #FAFAFA;
}

.mrpTableSelect .ant-select-selector {
  height: 17px !important;
  width: 110px !important;
  font-size: 12px;
}

.mrpTableSelect .ant-select-selector .ant-select-selection-search-input {
  height: 17px !important;
}

.mrpTableSelect  .ant-select-selection-item, .mrpTableSelect .ant-select-selection-placeholder {
  line-height: 17px !important;
}

.mrpTableSelect .ant-select-arrow {
  font-size: 10px !important;
}

.clearButton {
  height: 17px;
  line-height: 17px;
  font-size: 12px;
  margin-top: 2px;
}