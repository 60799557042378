.sortButton {
  font-size: 26px
}
.titleHeader {
  font-size: 16px
}
.applyContainer {
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 0;
  justify-items: center;
}

.applyButton {
  width: 100%;
  height: 100%
}

