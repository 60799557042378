.mainHeader {
    letter-spacing: 10px; 
    margin-top: 6px;
    overflow-wrap: normal !important;
    text-align: center
}

.toggle {
    font-size: 14px;
    color: white;
}

.toggle button.ant-switch {
    background-color: rgba(255, 255, 255, 0.5);
} 

.toggle button.ant-switch.ant-switch-checked{
    background-color: #1890ff;
}

.selectorTooltip .ant-tooltip-content .ant-tooltip-inner {
    white-space: pre;
    max-height: 30vh;
    overflow-x: auto;
    box-shadow: 5px 5px 3px rgba(0, 0, 0, 0.3);
}

/* Custom scrollbar width */
.selectorTooltip ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

  /* Custom scrollbar track */
.selectorTooltip ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}

  /* Custom scrollbar handle */
.selectorTooltip ::-webkit-scrollbar-thumb {
    background: lightgrey; 
    border-radius: 10px;
}

.formIcon {
    font-size: 22px ;
    margin-top: 22px;
}

.anticon.anticon-form.formIcon {
    color: white; 
}