.item {
  margin-bottom: 0px;
}

.timelineHeader {
  font-weight: bold;
  font-size: larger;
}

.ant-timeline {
  padding-top: 20px;
}

.ant-timeline-item {
  padding-bottom: 30px;
}

.feature-header {
  min-width: 35vw;
  min-height: 50vh
}