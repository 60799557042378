:root {
    --prod-color: #001529;
    --non-prod-color: #811147;

    --prod-title: #ff4d4f;
    --non-prod-title: #ffffff;

    --non-prod-contrast-dark: #1890ff;

    --contrast-color: #1890ff;
}