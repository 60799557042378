.ant-collapse-content-box {
  padding: 10px 16px !important;
}
.full-tab {
  height: calc(87vh);
  margin-top: 0px
}


.topRightButton {
  position: relative;
  z-index: 9;
  float: right;
  margin-right: 10px;
}

.topRightGraphButton {
  position: absolute;
  z-index: 99;
  right: 60px;
}

.leftColumn .ant-page-header-content {
  padding-top: 0px;
}

.ant-collapse-header {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 14px;
}

.leftColumn .ant-page-header-heading-title {
  margin-right: 2px;
}

.leftColumn .ant-page-header-heading-left {
  margin: 0;
}

.plantTabPanel .ant-collapse-header {
  padding-top: 2px!important;
  padding-bottom: 2px!important;
}

.headerButton {
  margin-left: 4px;
}

.matDetsTabs.ant-tabs-top > .ant-tabs-nav {
  margin: 0;
}

.ant-tabs-small.matDetsTabs .ant-tabs-tab {
  margin-top: 5px;
  margin-bottom: 4px;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 9px;
}

.matDetsTabs .ant-tabs-tab-active{
  background-color: #1890ff25;
  padding-left: 8px!important;
  padding-right: 8px!important;
  border: 1px solid #1890ff;
  border-radius: 6px;
}

.matDetsTabs.compact .ant-tabs-tab-active{
  padding-left: 2px!important;
  padding-right: 2px!important;
}

.matDetsTabs .ant-tabs-content.ant-tabs-content-top {
  height: 100%
}

.matDetsTabs .ant-tabs-ink-bar-animated {
  display: none
}

.noContent {
  color: #999;
}

.hasContent {
  color: inherit;
}
