.divDisplayColFilter {
    z-index: 1;
    float: right;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}

.divFloatRight {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 20px;
    align-items: flex-end;
}

.material-tab-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.columnsAndViews {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.material-tab-header .tableTitle {
    overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  font-weight: 700;
}

.material-tab-header .wrap-content {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: flex-end;
    width: 100%;
}


.matlist .ant-table-summary {
    background-color: rgb(167, 166, 166);
}

.colFilterMenu {
    height: 500px;
    overflow: auto;
    width: 450px; 
}

.mmTreeSelect .ant-select-selector .ant-select-selection-overflow .ant-select-selection-overflow-item-rest .ant-select-selection-item {
    background-color: white;
    border: 0px;
    width: 100%;
}

.clsFilterOutlined {
    font-size: 30px;
    color: #08c;
    float: right;
    margin-top: 5px;
}

.togglePlants {
    font-size: 14px;
}

.materialList .ant-table-tbody>tr>td {
    padding: 8px 0px 8px 12px;
    box-sizing: border-box;
}

.materialList .ant-table-cell-fix-left {
    padding: 8px!important;
    box-sizing: border-box;
}

/* Material List table header filter's position */
.materialList th.ant-table-cell > .ant-table-filter-column{
    display: block !important;
}

.materialList th.ant-table-cell > .ant-table-filter-column > .ant-table-filter-trigger{
    display: block !important;
    margin: 4px 0px !important;
}

.materialList .ant-table-thead > tr > th {
    padding: 8px;
    box-sizing: border-box;
}
/* Material List table header filter's position */

.invalidInput {
    border: 1px solid #e24973 !important;
}

.invalidInput:focus {
    box-shadow: 0 0 0 2px rgb(255 77 79 / 20%) !important;
}
.MatlistSummary .ant-table-cell,
.MatlistSummary .ant-table-cell-fix-left
.MatlistSummary .ant-table-cell-fix-left-last {
    background-color: rgb(250, 250, 250);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px 16px;
    box-sizing: border-box;
}

.iconButton {
    height: 100%;
    background-color: transparent;
    border: 1px solid #ddd;
    padding: 8px 8px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

