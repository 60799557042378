.widgetContainer {
    --repeat: 1;
}

@media screen and (min-width: 1200px) {
    .widgetContainer {
        --repeat: 2;
    }
}

@media screen and (min-width: 1800px) {
    .widgetContainer {
        --repeat: 3;
    }
}

.widgetContainer {
    display: grid;
    grid-template-columns: repeat(var(--repeat), minmax(550px, 1fr) );
    gap: 16px;
    padding-top: 16px;
}

.widgets {
    width: 100%;
    -webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.25);
}