.divFloatRight{
    float: right;
}
.btnGreyBG{
    background: grey!important;
    border: 1px solid transparent!important;
    color: white!important;
}
.btnWhiteBG{
    background: white!important;
    color: #000000d9!important;
    border: 1px solid transparent!important;
    box-shadow: 0 2px #00000004;
    border-color: #d9d9d9!important;
}

.tableRecordCount{
    margin-right: 10px;
    float: right;
    margin-top:-10px;
}
.tableTitle{
    font-weight: bold;
    font-size: 22px;
}
