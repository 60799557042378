.plantTable .ant-table {
  font-size: 12px;
}
.plantTable .ant-table-tbody > tr > td {
  height: 5px;
  padding: 0 4px;
}
.plantTable .ant-table-thead > tr > th {
  height: 5px;
  padding: 0 4px;
}
.plantTable .ant-table-row {
  height: 20px
}