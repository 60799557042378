.table-row-light, .table-row-light > .ant-table-cell {
    background-color: #ffffff;
}

.table-row-dark, .table-row-dark > .ant-table-selection-column.ant-table-cell-fix-left,
.table-row-dark > .ant-table-cell {
    background-color: #deebf769;
}

.ant-table-row-selected > .ant-table-selection-column.ant-table-cell-fix-left{
    background-color: #dfeff7 !important;
}
.table-row-light:hover td, .table-row-dark:hover td {
    background-color: #c4cbd9 !important;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: #cff0ff !important;
}

.ant-table .ant-table-body .ant-checkbox-wrapper {
    align-items: center;
}
  
.ant-table .ant-table-body .ant-checkbox-checked::after {
    border: none;
}
  
.ant-table .ant-table-body .ant-checkbox {
    display: contents;
}

.mainLayout {
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.innerLayout {
    width: 100%;
    height: 100%;
    max-width: calc(100vw - 80px);
    background-color: #fff;
    flex: 1;
    overflow: hidden;
}

.content {
    padding: 2px 12px 0px 12px;
    box-sizing: border-box;
    height: calc(100% - 65px);
    width: calc(100vw - 80px);
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
}