
.plannedOrderModal .ant-modal-content {
    width: 100vw;
  } 

  .buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    flex-direction: row;
    gap: 1rem;
  }

  .openDocsTooltip {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
}

.dateInputField {
  border: 1px solid #d9d9d9;
  background-color: #fff;
  padding: 4px 11px;
  box-sizing: border-box;
}

.deviation {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0px;
  line-height: 16px;
  font-size: 14px;
  gap: 4px;
}

.deviationValue {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
  width: 100%;
}

.reservationWorkOrder {
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: space-between;
  white-space: nowrap;
}

.reservationTooltip {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #2e2e2e;
  padding: 0.5rem;
}
